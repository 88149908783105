<template>
  <div class="feedback">
    <div class="feedback-wrapper">
      <div class="feedback-header">
        <h2>{{ i18n.title }}</h2>
        <div class="feedback-controls">
          <Button
            type="clear"
            class="feedback-control mr-3"
            @click="toPrev"
          >
            <Icon type="arrow-big-left" />
          </Button>
          <Button
            type="clear"
            class="feedback-control"
            @click="toNext"
          >
            <Icon type="arrow-big" />
          </Button>
        </div>
      </div>
      <div class="feedback-sliders">
        <div
          ref="sliderClone"
          class="feedback-slider _clone"
        >
          <div class="feedback-reviews">
            <div
              v-for="(review, i) in i18n.reviews"
              :key="'review-clone' + i"
              class="feedback-review"
            >
              <div class="feedback-review__city">
                {{ review.city }}
              </div>
              <h4 class="mb-3 mb-sm-4">
                {{ review.name }}
              </h4>
              <p class="feedback-review__content">
                {{ review.content }}
              </p>
            </div>
          </div>
        </div>
        <div
          ref="slider"
          class="feedback-slider _scroll"
        >
          <div class="feedback-reviews">
            <div
              v-for="(review, i) in i18n.reviews"
              :key="'review' + i"
              class="feedback-review"
            >
              <div class="feedback-review__city">
                {{ review.city }}
              </div>
              <h4 class="mb-3 mb-sm-4">
                {{ review.name }}
              </h4>
              <p class="feedback-review__content">
                {{ review.content }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Feedback",
  props: {
    i18n: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$refs.slider.onscroll = () => {
      this.$refs.sliderClone.style.left =
				-1 * this.$refs.slider.scrollLeft + "px";
    };
  },
  methods: {
    toPrev() {
      const { scroller, itemWidth } = this._getStateScroll();
      if (scroller.scrollLeft != 0) {
        scroller.scrollBy({
          left: -itemWidth,
          top: 0,
          behavior: "smooth",
        });
        return;
      }

      scroller.scrollTo({
        left: scroller.scrollWidth,
        top: 0,
        behavior: "smooth",
      });
    },
    toNext() {
      const { scroller, itemWidth } = this._getStateScroll();
      const scroll = scroller.scrollLeft + itemWidth;
      const endScroll = scroller.scrollWidth - itemWidth;
      console.log(endScroll - scroll, itemWidth);

      if (endScroll - scroll > itemWidth) {
        scroller.scrollBy({
          left: itemWidth,
          top: 0,
          behavior: "smooth",
        });
        return;
      }

      scroller.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    },
    _getStateScroll() {
      const scroller = this.$refs.slider;
      return {
        scroller,
        itemWidth:
					scroller.querySelector(".feedback-review").clientWidth,
      };
    },
  },
};
</script>

<style lang="scss" src="./feedback.scss" />
